import '../../css/share/common.scss';

$(function() {


    //--------------------- imagesLoaded ---------------------//

    imagesLoaded("html, body", function() {

        //     $('a[href="#"]').click(function(e) {
        //         e.preventDefault();
        //     });
        //     // lazyload();

        //     app_header.mount('#app_header');
        //     app_footer.mount('#app_footer');
        //     app_instruction.mount('#app_instruction');
        $('head').append('<script defer src="/js/common_lazy.min.js"></script>');
        if ($(".index").length > 0) {
            $('head').append('<script defer src="/js/index.min.js"></script>');
        }
    });

});